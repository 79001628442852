<template>
  <div class="animated">
    <b-card>
      <div slot="header">
        <font-awesome-icon icon="rss" style="margin-right: 5px" />Autofeeds
      </div>
      <b-button variant="success" v-on:click="openCreateModal">
        <font-awesome-icon icon="rss" style="margin-right: 5px" />Create new
        autofeed
      </b-button>
      <hr />
      <v-client-table
        :columns="columns"
        :data="autofeeds"
        :options="options"
        :theme="theme"
        id="dataTable"
        style="width: 100%"
      >
        <p slot="message" slot-scope="props" block>
          {{ props.row.message }}
          <b-badge
            v-if="Object.keys(props.row.embed).length > 1"
            class="ml-2"
            variant="primary"
            pill
            >embed</b-badge
          >
        </p>
        <p slot="channel" slot-scope="props" block>
          #{{ channel_from_id(props.row.channel).name }}
        </p>
        <p slot="role" slot-scope="props" block>
          {{ role_from_id(props.row.role).name }}
        </p>
        <p slot="expires" slot-scope="props" block>
          {{ toLocal(props.row.expires) }}
        </p>
        <b-button
          slot="actions"
          slot-scope="props"
          block
          variant="outline-primary"
          v-b-tooltip.hover
          :disabled="props.row.id == undefined"
          v-on:click="openEditModal(props.row)"
          >Edit</b-button
        >
      </v-client-table>
      <b>Note:</b> Feeds needs to be scheduled at least 5 minutes in the future.
    </b-card>
    <b-modal
      size="lg"
      v-model="largeModal"
      @ok="largeModal = false"
      :no-enforce-focus="true"
    >
      <template slot="modal-title">
        <h4 class="smalltitle">Edit Autofeed</h4>
      </template>
      <b-form-group>
        <h4 class="tinytitle">Channel</h4>
        <multiselect
          v-model="feed_channel"
          id="server_channel_select"
          track-by="id"
          label="name"
          placeholder="Please select a channel"
          open-direction="bottom"
          :clear-on-select="false"
          :close-on-select="true"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="false"
          :options="groupedChannels"
          :allow-empty="false"
          group-values="channels"
          group-label="category"
          :group-select="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <font-awesome-icon style="color: #72767d" icon="hashtag" />
              <span class="option__title ml-2">{{ props.option.name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                <font-awesome-icon
                  style="color: #72767d"
                  :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                />
                {{ props.option.name || props.option.$groupLabel.name }}
              </span>
            </div>
          </template>
        </multiselect>
        <hr />
        <h4 class="tinytitle">Message</h4>
        <b-input-group>
          <b-form-textarea
            :maxlength="100000"
            rows="6"
            type="text"
            placeholder="Please enter your message"
            class="col-12"
            v-model="message_content"
          ></b-form-textarea>
        </b-input-group>
        <small>{{ message_content.length }}/2000</small>
        <hr />
        <EmbedBuilder ref="embed_edit"></EmbedBuilder>
        <hr />
        <h4 class="tinytitle">Date</h4>
        <p>Uses your local time zone</p>
        <b-input-group>
          <b-input-group-prepend
            v-b-tooltip.hover
            title="Uses your local timezone"
          >
            <b-input-group-text>
              <i class="icon-calendar"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <flat-pickr
            v-model="timestamp"
            :config="config"
            class="form-control"
            placeholder="Select date"
            name="date"
          ></flat-pickr>
        </b-input-group>
        <hr />
        <b-form-group>
          <h4 class="tinytitle">Role to mention</h4>
          <multiselect
            v-model="autofeed_role"
            id="mute_role_select"
            track-by="id"
            label="name"
            placeholder="Silent (no role)"
            open-direction="bottom"
            :searchable="true"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="sorted_roles"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color: #72767d" icon="user-tag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span
                  class="option__title"
                  :style="{ color: '#' + intToHex(props.option.color) }"
                  >{{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <hr />
        <h4 class="tinytitle">Repeat every</h4>
        <b-input-group>
          <b-form-input
            type="number"
            placeholder="0"
            v-model="datetimes[0]"
            min="0"
            v-on:keydown="handleOnlyNumberkeyInput"
          ></b-form-input>
          <b-input-group-append mr="1">
            <b-input-group-text class="mr-1">Months</b-input-group-text>
          </b-input-group-append>

          <b-form-input
            type="number"
            placeholder="0"
            v-model="datetimes[1]"
            min="0"
            v-on:keydown="handleOnlyNumberkeyInput"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text class="mr-1">Days</b-input-group-text>
          </b-input-group-append>

          <b-form-input
            type="number"
            placeholder="0"
            v-model="datetimes[2]"
            min="0"
            v-on:keydown="handleOnlyNumberkeyInput"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text class="mr-1">Hours</b-input-group-text>
          </b-input-group-append>

          <b-form-input
            type="number"
            placeholder="0"
            v-model="datetimes[3]"
            min="0"
            v-on:keydown="handleOnlyNumberkeyInput"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text>Minutes</b-input-group-text>
          </b-input-group-append>
        </b-input-group>
        <p>
          Please note: Autofeeds sent less than 60 seconds into the future will
          not be repeated. Autofeeds repeated more often than daily will not
          edit the role to make it mentionable (unless you're a patron)
        </p>
      </b-form-group>
      <div slot="modal-footer" class="w-100">
        <b-container
          fluid
          class="text-light text-center"
          style="padding-right: 0px"
        >
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2">
              <b-button
                v-b-tooltip.hover
                title="Delete autofeed"
                variant="ghost-danger"
                v-on:click="deleteTrigger"
              >
                <i class="icon-trash"></i>
              </b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button
                variant="ghost-secondary"
                v-on:click="largeModal = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="primary" v-on:click="editAutofeed"
                >Save</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      size="lg"
      v-model="createModal"
      @ok="xd = false"
      :no-enforce-focus="true"
    >
      <template slot="modal-title">
        <h4 class="smalltitle">Create Autofeed</h4>
      </template>
      <b-form-group>
        <h4 class="tinytitle">Channel</h4>
        <multiselect
          v-model="feed_channel"
          id="server_channel_select"
          track-by="id"
          label="name"
          placeholder="Please select a channel"
          open-direction="bottom"
          :clear-on-select="false"
          :close-on-select="true"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="false"
          :options="groupedChannels"
          :allow-empty="false"
          group-values="channels"
          group-label="category"
          :group-select="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <font-awesome-icon style="color: #72767d" icon="hashtag" />
              <span class="option__title ml-2">{{ props.option.name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                <font-awesome-icon
                  style="color: #72767d"
                  :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                />
                {{ props.option.name || props.option.$groupLabel.name }}
              </span>
            </div>
          </template>
        </multiselect>
        <hr />
        <h4 class="tinytitle">Message</h4>
        <b-input-group>
          <b-form-textarea
            :maxlength="100000"
            rows="6"
            type="text"
            placeholder="Please enter your message"
            class="col-12"
            v-model="message_content"
          ></b-form-textarea>
        </b-input-group>
        <small>{{ message_content.length }}/2000</small>
        <hr />
        <EmbedBuilder ref="embed_create"></EmbedBuilder>
        <hr />
        <h4 class="tinytitle">Date</h4>
        <p>Uses your local time zone</p>
        <b-input-group>
          <b-input-group-prepend
            v-b-tooltip.hover
            title="Uses your local timezone"
          >
            <b-input-group-text>
              <i class="icon-calendar"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <flat-pickr
            v-model="timestamp"
            :config="config"
            class="form-control"
            placeholder="Select date"
            name="date"
          ></flat-pickr>
        </b-input-group>
        <hr />
        <b-form-group>
          <h4 class="tinytitle">Role to mention</h4>
          <multiselect
            v-model="autofeed_role"
            id="mute_role_select"
            track-by="id"
            label="name"
            placeholder="Silent (no role)"
            open-direction="bottom"
            :searchable="true"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="sorted_roles"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color: #72767d" icon="user-tag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span
                  class="option__title"
                  :style="{ color: '#' + intToHex(props.option.color) }"
                  >{{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <hr />
        <h4 class="tinytitle">Repeat every</h4>
        <div class="col-12">
          <b-row>
            <b-input-group
              class="my-1 col-sm-3 pr-sm-1 pl-sm-0"
              style="margin-right=0px !important;"
            >
              <b-form-input
                type="number"
                placeholder="0"
                v-model="datetimes[0]"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>Months</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-sm-3 px-sm-1 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                v-model="datetimes[1]"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>Days</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-sm-3 px-sm-1 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                v-model="datetimes[2]"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>Hours</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-sm-3 px-sm-1 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                v-model="datetimes[3]"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p>
              Please note: Autofeeds sent less than 60 seconds into the future
              will not be repeated. Autofeeds repeated more often than daily
              will not edit the role to make it mentionable (unless you're a
              patron)
            </p>
          </b-row>
        </div>
      </b-form-group>
      <div slot="modal-footer" class="w-100">
        <b-container
          fluid
          class="text-light text-center"
          style="padding-right: 0px"
        >
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button
                variant="ghost-secondary"
                v-on:click="createModal = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="success" v-on:click="createAutofeed"
                >Create</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/themes/dark.css";
import EmbedBuilder from "@/views/oc/EmbedBuilder";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);
Vue.use(ClientTable);

export default {
  name: "DataTable",
  components: {
    ClientTable,
    Event,
    cSwitch,
    flatPickr,
    EmbedBuilder,
    Multiselect
  },
  notifications: {
    added_trigger: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Autofeed created"
    },
    edited_trigger: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Autofeed updated"
    },
    deleted_trigger: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Autofeed deleted"
    },
    increase_repeat_duration: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Lowest possible repeat duration is 1 hour, please fix."
    },
    fill_in_fields: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Please fill in all fields"
    }
  },
  data: function() {
    return {
      columns: ["message", "channel", "role", "expires", "actions"],
      autofeeds: [],
      channels: [],
      roles: [],
      message_content: "",
      timestamp: null,
      feed_channel: null,
      feed_id: null,
      datetimes: [0, 0, 0, 0],
      autofeed_role: { name: "silent", id: null },
      config: {
        altInput: true,
        enableTime: true,
        altFormat: "F j, Y H:i",
        dateFormat: "Z",
        time_24hr: true,
        minDate: new Date()
      },
      options: {
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}"
        },
        headings: {
          expires: "Next Announcement"
        },
        columnsDisplay: {
          message: "not_mobile",
          role: "not_mobile"
        },
        sortable: ["message", "id", "expires"],
        filterable: ["message"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: true,
          nav: "scroll"
        },
        childRow: false,
        highlightMatches: true
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      largeModal: false,
      createModal: false
    };
  },
  methods: {
    toLocal(date_string) {
      var event = new Date(Date.parse(date_string));
      return event.toLocaleString("en-GB");
    },
    role_from_id(id) {
      return this.roles.find(x => x.id == id) || { name: "silent", id: null };
    },
    channel_from_id(id) {
      return (
        this.channels.find(x => x.id == id) || {
          name: "unknown channel (deleted?)"
        }
      );
    },
    openEditModal(row) {
      this.datetimes = row.repeat_duration;
      this.message_content = row.message.trim();
      this.feed_channel = this.channel_from_id(row.channel);
      this.autofeed_role = this.role_from_id(row.role) || null; // == null ? null : row.role.id; //{name: 'No role selected', id: null}
      this.timestamp = row.expires;
      this.largeModal = true;
      setTimeout(() => {
        this.$refs.embed_edit.apply_json(row.embed);
        this.feed_id = row.id;
      }, 20);

      this.datetimes = row.repeat_duration;
    },
    openCreateModal() {
      this.datetimes = [0, 0, 0, 0];
      this.message_content = "";
      this.feed_channel = null;
      this.autofeed_role = null;
      this.timestamp = null;
      this.createModal = true;
      setTimeout(() => {
        this.$refs.embed_create.resetEmbed();
        this.$refs.embed_create.show_embed = false;
      }, 20);
    },
    editAutofeed() {
      if (
        (!this.message_content &&
          Object.keys(this.$refs.embed_edit.full_json).length < 2) ||
        !this.feed_channel ||
        !this.timestamp
      ) {
        return this.fill_in_fields();
      }
      var sum = 0;
      var dts = [9999, 9999, 3600, 60];
      this.datetimes.map((x, y) => (sum += x * dts[y]));
      if (sum != 0 && sum < 3600) {
        return this.increase_repeat_duration();
      }

      this.autofeeds.forEach(e => {
        if (e.id == this.feed_id) {
          e.message = this.message_content;
          e.channel = this.feed_channel.id;
          e.role = this.autofeed_role ? this.autofeed_role.id : null;
          e.expires = this.timestamp;
          e.embed = this.$refs.embed_edit.full_json;
        }
      });
      this.patchAutofeed();
      this.edited_trigger();
    },
    createAutofeed() {
      if (
        (!this.message_content &&
          Object.keys(this.$refs.embed_create.full_json).length < 2) ||
        !this.feed_channel ||
        !this.timestamp
      ) {
        return this.fill_in_fields();
      }
      var sum = 0;
      var dts = [9999, 9999, 3600, 60];
      this.datetimes.map((x, y) => (sum += x * dts[y]));
      if (sum != 0 && sum < 3600) {
        return this.increase_repeat_duration();
      }
      this.autofeeds.push({
        message: this.message_content,
        channel: this.feed_channel.id,
        role: this.autofeed_role || { name: "silent", id: null },
        expires: this.timestamp,
        embed: this.$refs.embed_create.full_json,
        repeat_duration: this.datetimes
      });

      this.axios
        .post("/api/v1/servers/" + this.$route.params.guild_id + "/autofeeds", {
          timestamp: this.timestamp,
          repeat_duration: this.datetimes,
          feed_channel: this.feed_channel.id,
          feed_role: this.autofeed_role ? this.autofeed_role.id : null,
          message: this.message_content,
          embed: this.$refs.embed_create.full_json
        })

        .then(r => {
           if(resp.status==200)
        {
        this.added_trigger();};
      },this.closeModals());
        setTimeout(
            () =>
        this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/autofeeds")
      .then(r => {
        this.autofeeds = r.data.autofeeds;
        this.channels = r.data.channels;
        this.roles = r.data.roles;
      }),
      3000);

    },
    patchAutofeed() {
      this.axios
        .patch(
          "/api/v1/servers/" + this.$route.params.guild_id + "/autofeeds",
          {
            message: this.message_content,
            role: this.autofeed_role ? this.autofeed_role.id : null,
            channel: this.feed_channel.id,
            embed: this.$refs.embed_edit.full_json,
            timestamp: this.timestamp,
            repeat_duration: this.datetimes,
            id: this.feed_id
          }
        )
        .then(this.closeModals());
    },
    deleteTrigger() {
      this.axios
        .delete(
          "/api/v1/servers/" +
            this.$route.params.guild_id +
            "/autofeeds/" +
            this.feed_id
        )
        .then(this.closeModals());
      for (let [i, e] of this.autofeeds.entries()) {
        if (e.id === this.feed_id) {
          this.autofeeds.splice(i, 1);
        }
      }
      this.deleted_trigger();
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16);
    },
    closeModals() {
      this.largeModal = false;
      this.createModal = false;
    }
  },
  computed: {
    sorted_roles() {
      return this.roles.sort((a, b) => a.position - b.position);
    },
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/autofeeds")
      .then(r => {
        this.autofeeds = r.data.autofeeds;
        this.channels = r.data.channels;
        this.roles = r.data.roles;
      });
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/autofeeds")
      .then(r => {
        this.autofeeds = r.data.autofeeds;
        this.channels = r.data.channels;
        this.roles = r.data.roles;
        this.$Progress.finish();
      })
      .catch(err => {
        const resp = err.response;
      });
  }
};
</script>

<style lang="scss">
#dataTable {
  width: 95%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .td {
    word-wrap: !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
